import { CheckboxContainer, Content, Form, FormButton } from "./styles";
// import SignersList from "../../SignersList";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import refreshToken from "../../../../utils/refreshToken";
import { useAuth } from "../../../../hooks/auth";
import api from "../../../../services/api";
import OutsideClickHandler from "react-outside-click-handler";
import { useToast } from "../../../../hooks/toast";
import { MenuItem, Select } from "@material-ui/core";
import { MdOutlineGroupAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import SignersList from "../../../../components/SignersList";

interface IUsers {
  id: number;
  first_name: string;
  email: string;
  phone: string;
}

interface ISignerType {
  value: string;
  label: string;
}

interface ISigner {
  signAs: string;
  email: string;
  name: string;
  signOutside: boolean;
  user_id: undefined | number;
}

interface ISignerRegister {
  signersList: ISigner[];
  onRemoveSigner: (signerEmail: string) => void;
  onFormSubmit: (registeredSigner: ISigner) => void;
}

const DEFAULT_SIGNER = {
  user_id: undefined,
  email: "",
  name: "",
  signOutside: false,
  signAs: "lessor",
};

const ICON_SIZE = 24;

function SignersStep({ onRemoveSigner, onFormSubmit, signersList }: ISignerRegister) {
  const { access_token, dateToExpires, user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const [users, setUsers] = useState<IUsers[]>([]);
  const [signer, setSigner] = useState<ISigner>(DEFAULT_SIGNER);

  const [signerTypes, setSignerTypes] = useState<ISignerType[]>([]);
  const [fetchingSignerTypes, setFetchingSignerTypes] = useState(false);

  let cancelToken: undefined | CancelTokenSource;

  useEffect(() => {
    async function loadApi() {
      setFetchingSignerTypes(true);
      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${token ?? access_token}`;
        const response = await api.get("/api/adm/signers-types");

        setSignerTypes(() => {
          return response.data.data.map((signerType: ISignerType) => {
            return { value: signerType.value, label: signerType.label };
          });
        });
      } catch (err: any) {
        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }
      }
      setFetchingSignerTypes(false);
    }

    if (!!user) {
      loadApi();
    } else {
      history.push("/");
    }
  }, [dateToExpires, access_token, user, history, addToast]);

  function handleChange(identifier: string, value: any) {
    setSigner((prevSigner) => ({ ...prevSigner, [identifier]: value }));
  }

  function handleSelectSignerType(signAs: string) {
    handleChange("signAs", signAs);
  }

  function onEmailChange(event: ChangeEvent<HTMLInputElement>) {
    handleChange("email", event.target.value);
  }

  const onSearcheableEmailChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const searchTerm = e.target.value;

      handleChange("email", searchTerm);

      if (cancelToken !== undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }

      // eslint-disable-next-line
      cancelToken = axios.CancelToken.source();

      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${
          !!token ? token : access_token
        }`;

        const response = await api.get("/api/adm/users", {
          params: {
            email: searchTerm,
          },
          cancelToken: cancelToken?.token,
        });

        if (response.data.data.users.length === 0) {
          addToast({
            title: "Nenhum usuário encontrado",
            type: "error",
          });
        }

        setUsers(response.data.data.users);
      } catch (err) {
        console.log(err);
      }
    },
    [cancelToken, access_token, dateToExpires, addToast]
  );

  function onNameChange(event: ChangeEvent<HTMLInputElement>) {
    handleChange("name", event.target.value);
  }

  function onHaveAccountChange(event: ChangeEvent<HTMLInputElement>) {
    handleChange("signOutside", event.target.checked);
    if (event.target.checked) {
      handleChange("user_id", undefined);
    }
    else {
        handleChange("name", '');
        handleChange("email", '');
    }
  }

  function handleSelectUser(user_id: number | undefined) {
    const selectedUser = users.find((client) => client.id === user_id);

    if (selectedUser) {
      setSigner((prevSigner) => ({
        ...prevSigner,
        user_id: selectedUser.id,
        name: selectedUser.first_name,
        email: selectedUser.email,
      }));
    }
    setUsers([]);
  }

  function handleAddSigner(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const signerBody = {
      ...signer,
      user_id: signer.signOutside ? undefined : signer.user_id,
    };
    if (signer.email.length === 0 || !signer.email.includes("@")) {
      addToast({
        title: "Email inválido",
        type: "error",
      });
      handleChange("email", "");
      return;
    }
    if (signer.name.length === 0) {
      addToast({
        title: "Nome inválido",
        type: "error",
      });

      return;
    }
    if (signer.signAs.length === 0) {
      addToast({
        title: "Tipo de signatário inválido",
        type: "error",
      });

      return;
    }
    setSigner((prevSigner) => ({ ...prevSigner, ...DEFAULT_SIGNER }));
    onFormSubmit(signerBody);
  }

  return (
    <Content>
      <Form onSubmit={handleAddSigner}>
        <label>Assinar como:</label>
        {!fetchingSignerTypes && signerTypes?.length > 0 && (
          <Select
            onChange={(e) => handleSelectSignerType(e.target.value as string)}
            value={signer.signAs}
          >
            {signerTypes.map((signerType) => (
              <MenuItem value={signerType.value} key={signerType.value}>
                {signerType.label}
              </MenuItem>
            ))}
          </Select>
        )}
        {fetchingSignerTypes && <p>Carregando...</p>}
        <CheckboxContainer id="checkbox-container">
          <input type="checkbox" onChange={onHaveAccountChange} />
          <label>
            Este signatário vai assinar sem criar cadastro no sistema.
          </label>
        </CheckboxContainer>
        <label>Email:</label>
        <input
          value={signer.email}
          onChange={
            signer.signOutside ? onEmailChange : onSearcheableEmailChange
          }
        />
        {users.length >= 1 ? (
          <OutsideClickHandler
            onOutsideClick={() => {
              handleChange("email", "");
              setUsers([]);
            }}
          >
            {users.map((user) => (
              <button
                key={user.id}
                type="button"
                onClick={() => handleSelectUser(user.id)}
              >
                {user.email}
              </button>
            ))}
          </OutsideClickHandler>
        ) : (
          <></>
        )}
        {signer.signOutside && (
          <>
            <label id="name-lbl">Nome:</label>{" "}
            <input value={signer.name} onChange={onNameChange} />
          </>
        )}

        <FormButton>
          <span id="styled-btn">
            Adicionar Signatário <MdOutlineGroupAdd size={ICON_SIZE} />
          </span>
        </FormButton>
      </Form>
      <SignersList signers={signersList} onSignerRemove={onRemoveSigner} />
    </Content>
  );
}

export default SignersStep;
