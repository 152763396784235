import styled from "styled-components";

export const Content = styled.div`
  max-width: 480px;
  margin: 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;

  padding: 20px 0px 20px 0px;

  width: 400px;

  > h3 {
    color: #666666;

    font-weight: 600;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    padding: 24px 0 24px 0px;

    width: 330px;
  }
`;

export const FormContainer = styled.form`
  width: 80%;

  padding-bottom: 8px;

  align-items: flex-start;
  flex-direction: row;

  margin: 40px 40px 0px;
  font-size: 18px;
  color: #666666;

  position: relative;

  > p {
    font-weight: bold;
    margin: 24px 0 8px;
  }

  > input {
    font-size: 18px;
    width: 100%;

    padding: 8px 0px 8px 0px;

    border: 0;
    border-bottom: 1px solid #b6b6b6;

    cursor: default;
  }

  .iconContainer {
    position: absolute;
    right: 2%;
    top: 50%;
    cursor: pointer;
  }

  #consultants {
    width: 100%;
    max-height: 350px;

    overflow-y: auto;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    position: absolute;

    left: 50;
    top: 25;
    z-index: 10000;

    background: #ffffff;

    border: 1px solid #b6b6b6;

    > button {
      padding: 8px 16px;

      width: 100%;

      font-size: 14px;

      text-align: start;

      transition: all 0.2s;

      &:hover {
        background: #f9f9f9;
      }
    }
  }

  #consultants {
    width: 21.5%;
    max-height: 350px;

    overflow-y: auto;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    position: fixed;

    left: 50;
    top: 25;
    z-index: 10000;

    background: #ffffff;

    border: 1px solid #b6b6b6;

    > button {
      padding: 8px 16px;

      width: 100%;

      font-size: 14px;

      text-align: start;

      transition: all 0.2s;

      &:hover {
        background: #f9f9f9;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  margin: 24px 20px 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 90%;

  > button {
    font-size: 18px;
    padding: 12px;

    margin: 8px 8px;
    color: #fff;

    font-weight: 500;
  }

  .btn-edit {
    background-color: #03bf34;
    font-weight: bold;
  }
  .btn-close {
    color: #666666;
    border: 2px solid #666666;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    width: 90%;
  }
`;
