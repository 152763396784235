import React, { useState } from "react";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import {
  Content,
  TitleContainer,
  FormContainer,
  ButtonContainer,
} from "./styles";

import { FiX } from "react-icons/fi";
import { RiArrowLeftRightLine } from "react-icons/ri";

import refreshToken from "../../utils/refreshToken";
import api from "../../services/api";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationError";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import { useRefresh } from "../../hooks/refresh";
import OutsideClickHandler from "react-outside-click-handler";

interface IConsultant {
  id: number | null;
  first_name: string;
}

interface IParam {
  schedule: string;
  consultants: IConsultant[];
}

interface IModalConsult {
  open: boolean;
  functionToClose(): void;
  param: IParam;
}

const ModalConsult: React.FC<IModalConsult> = ({
  open,
  functionToClose,
  param,
}: IModalConsult) => {
  const schedule = param.schedule;
  const { access_token, dateToExpires } = useAuth();
  const { addToast } = useToast();
  const { handleRefresh } = useRefresh();

  const consultants = param.consultants;

  const [selectedConsultant, setSelectedConsultant] = useState(
    consultants.find((consultant) => consultant.id === +schedule.split("|")[2])
  );
  const [showConsultantsList, setShowConsultantsList] = useState(false);
  const [loadingRequestToUpdate, setloadingRequestToUpdate] = useState(false);

  if (consultants.length === 0) {
    addToast({
      title: "Nenhum consultor encontrado",
      type: "error",
    });
  }

  const handleUpdate = async () => {
    setloadingRequestToUpdate(true);

    const data = { consultantIdSelected: selectedConsultant?.id };

    try {
      const schema = Yup.object().shape({
        consultantIdSelected: Yup.number().required("Selecione um consultor"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.put("/api/adm/schedules/change-consultants", {
        schedule,
        consultant_id: selectedConsultant?.id,
      });

      if (!!response.data.success) {
        addToast({
          title: "Alteração concluída",
          type: "success",
          description: "",
        });

        handleRefresh();
        functionToClose();
      }
    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        addToast({
          title: errors[Object.keys(errors)[0]],
          type: "info",
          description: "",
        });
      } else if (!!err.response.data.errors) {
        const errorsNumber = Object.keys(err.response.data.errors).length;

        for (let i = 0; i < errorsNumber; i++) {
          err.response.data.errors[
            Object.keys(err.response.data.errors)[i]
          ].forEach((error: string) => {
            addToast({
              title: error,
              type: "error",
            });
          });
        }
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }

      setloadingRequestToUpdate(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={functionToClose}
      classNames={{
        modal: "modalUpdate",
      }}
      closeIcon={<FiX size={18} color="#000" />}
    >
      <Content>
        <TitleContainer>
          <h3>ALTERAR AGENDAMENTO</h3>
        </TitleContainer>
        <hr />
        <FormContainer>
          <p>Consultor:</p>
          <input value={selectedConsultant?.first_name} readOnly />
          <span
            className="iconContainer"
            onMouseDown={() => {
              setSelectedConsultant({ id: null, first_name: "" });
              setShowConsultantsList(true);
            }}
          >
            <RiArrowLeftRightLine size={24} color="#6ea4ff" />
          </span>
          {consultants.length >= 1 && showConsultantsList ? (
            <OutsideClickHandler
              onOutsideClick={() => {
                setSelectedConsultant({ id: null, first_name: "" });
                setShowConsultantsList(false);
              }}
            >
              <div id="consultants">
                {consultants.map((consultant) => (
                  <button
                    key={consultant.id}
                    type="button"
                    onClick={() => {
                      setSelectedConsultant(consultant);
                      setShowConsultantsList(false);
                    }}
                  >
                    {consultant.first_name}
                  </button>
                ))}
              </div>
            </OutsideClickHandler>
          ) : (
            <></>
          )}
        </FormContainer>

        <ButtonContainer>
          {!!loadingRequestToUpdate ? (
            <p>Carregando...</p>
          ) : (
            <button className="btn-edit" onClick={handleUpdate}>
              SALVAR ALTERAÇÕES
            </button>
          )}
          <button className="btn-close" onClick={functionToClose}>
            CANCELAR
          </button>
        </ButtonContainer>
      </Content>
    </Modal>
  );
};

export default ModalConsult;
