import { CheckboxContainer, Content, Form, FormButton } from "./styles";
import SignersList from "../../SignersList";
import { ChangeEvent, useCallback, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import refreshToken from "../../../utils/refreshToken";
import { useAuth } from "../../../hooks/auth";
import api from "../../../services/api";
import OutsideClickHandler from "react-outside-click-handler";
import { useToast } from "../../../hooks/toast";
import { MenuItem, Select } from "@material-ui/core";
import { MdOutlineGroupAdd } from "react-icons/md";

interface IUsers {
  id: number;
  first_name: string;
  email: string;
  phone: string;
}

interface ISignerType {
  value: string;
  label: string;
}

interface ISigner {
  signAs: string | unknown;
  email: string;
  name: string;
  signOutside: boolean;
  user_id: undefined | number;
}

interface ISignerRegister {
  signerTypes: ISignerType[];
  signersList: ISigner[];
  onRemoveSigner(signerEmail: string): void;
  onFormSubmit(registeredSigner: ISigner): void;
}

const SignersRegister: React.FC<ISignerRegister> = ({
  signerTypes,
  signersList,
  onRemoveSigner,
  onFormSubmit,
}: ISignerRegister) => {
  const [selectedSignerType, setSelectedSignerType] = useState<
    string | unknown
  >(signerTypes[0].value);
  const { access_token, dateToExpires } = useAuth();
  const { addToast } = useToast();
  const [users, setUsers] = useState<IUsers[]>([]);
  const [userId, setUserId] = useState<number>();
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [signOutside, setSignOutside] = useState<boolean>(false);
  const ICON_SIZE = 24;

  let cancelToken: undefined | CancelTokenSource;

  const onSignerSelect = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void => {
    if (!!event.target.value) {
      setSelectedSignerType(event.target.value);
    }
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const onSearcheableEmailChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const searchTerm = e.target.value;

      setEmail(searchTerm);

      if (cancelToken !== undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }

      // eslint-disable-next-line
      cancelToken = axios.CancelToken.source();

      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${
          !!token ? token : access_token
        }`;

        const response = await api.get("/api/adm/users", {
          params: {
            email: searchTerm,
          },
          cancelToken: cancelToken?.token,
        });

        if (response.data.data.users.length === 0) {
          addToast({
            title: "Nenhum usuário encontrado",
            type: "error",
          });
        }

        setUsers(response.data.data.users);
      } catch (err) {
        console.log(err);
      }
    },
    [cancelToken, access_token, dateToExpires, addToast]
  );

  const onNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value);
  };

  const onHaveAccountChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSignOutside(event.target.checked);
  };

  const handleSelectUser = useCallback(
    (user_id: number | undefined) => {
      const user_selected = users.find((client) => client.id === user_id);

      if (!!user_selected) {
        setUserId(user_selected.id);
        setEmail(user_selected.email);
        setName(user_selected.first_name);
      }
      setUsers([]);
    },
    [users]
  );

  const onSignerRegistered = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const signerBody: ISigner = {
      signAs: selectedSignerType,
      email: email,
      name: name,
      signOutside: signOutside,
      user_id: signOutside ? undefined : userId,
    };
    if (email.length === 0 || !email.includes("@")) {
      addToast({
        title: "Email inválido",
        type: "error",
      });
      setEmail("");
      return;
    }
    if (name.length === 0) {
      addToast({
        title: "Nome inválido",
        type: "error",
      });

      return;
    }
    onFormSubmit(signerBody);

    setEmail("");
    setSelectedSignerType(signerTypes[0].value);
    setName("");
    setSignOutside(false);
  };

  return (
    <Content>
      <Form onSubmit={onSignerRegistered}>
        <label>Assinar como:</label>
        <Select onChange={onSignerSelect} value={selectedSignerType}>
          {signerTypes.map((signerType) => (
            <MenuItem value={signerType.value} key={signerType.value}>
              {signerType.label}
            </MenuItem>
          ))}
        </Select>
        {/* <select onChange={onSignerSelect} value={selectedSignerType}>
          {signerTypes.map((signerType) => (
            <option value={signerType.value} key={signerType.value}>
              {signerType.label}
            </option>
          ))}
        </select> */}
        <CheckboxContainer id="checkbox-container">
          <input
            checked={signOutside}
            type="checkbox"
            onChange={onHaveAccountChange}
          />
          <label>
            Este signatário vai assinar sem criar cadastro no sistema.
          </label>
        </CheckboxContainer>
        <label>Email:</label>
        <input
          value={email}
          onChange={signOutside ? onEmailChange : onSearcheableEmailChange}
        />
        {users.length >= 1 ? (
          <OutsideClickHandler
            onOutsideClick={() => {
              setEmail("");
              setUsers([]);
            }}
          >
            {users.map((user) => (
              <button
                key={user.id}
                type="button"
                onClick={() => handleSelectUser(user.id)}
              >
                {user.email}
              </button>
            ))}
          </OutsideClickHandler>
        ) : (
          <></>
        )}
        {signOutside && <label id="name-lbl">Nome:</label>}
        {signOutside && <input value={name} onChange={onNameChange} />}
        <FormButton>
          <span id="styled-btn">
            Adicionar Signatário <MdOutlineGroupAdd size={ICON_SIZE}/>
          </span>
        </FormButton>
      </Form>
      <SignersList signers={signersList} onSignerRemove={onRemoveSigner} />
    </Content>
  );
};

export default SignersRegister;
