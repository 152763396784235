import styled from "styled-components";

export const Content = styled.div`
  max-width: 740px;
  padding-bottom: 3rem;
`;

export const TitleContainer = styled.div`
  border-bottom: 1px solid #bcbfc2;
  display: flex;
  justify-content: center;

  padding: 1.5rem 0;
  margin-bottom: 16px;
  width: 400px;
  > h3 {
    color: #666666;
    text-transform: uppercase;
    font-weight: 700;
  }

  @media (max-width: 767px) {
    padding: 1.5rem 1.5rem 1.5rem 0;

    width: 330px;
  }
`;

export const DataContainer = styled.div`
  width: 320px;

  padding-bottom: 8px;

  align-items: flex-start;
  justify-content: center;

  flex-direction: column;

  margin: 0 auto;

  > h3 {
    color: #dd6435;

    font-weight: 600;

    margin: 1.25em 0 0.5em;
  }

  > p {
    color: #666666;

    & + p {
      margin-top: 4px;
    }
  }

  > a {
    text-decoration: underline;
    color: #0219a3;
    cursor: pointer;
    overflow-wrap: break-word;
  }
`;

export const DataFieldsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FormDataField = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  flex-direction: column;

  > label {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;

    color: #666666;

    > p {
      margin-left: 6px;

      font-size: 10px;
      font-weight: bold;
    }
  }

  > input {
    width: 100%;

    border: 0;
    border-bottom: 1px solid #b6b6b6;

    color: #666666;

    background: transparent;

    padding: 4px 8px;

    margin-bottom: 8px;
  }

  > button {
    padding: 4px;
    margin: 8px 4px;
    color: #dd6435;
    background-color: #fff;
    border: 1px solid #dd6435;
    border-radius: 4px;
    font-size: ${12 / 16}rem;

    &:hover {
      background-color: #dd6435;
      color: #fff;
      transition: 200ms linear;
    }
  }
`;

export const ButtonsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 16px;
`;

export const Button = styled.button`
  margin: auto;
  width: 60%;
  padding: 8px 0;
  color: ${(props) => props.color};
  background-color: #fff;
  border: 1px solid ${(props) => props.color};
  border-radius: 4px;
  font-size: ${12 / 16}rem;
  text-transform: capitalize;

  &:hover {
    background-color: ${(props) => props.color};
    color: #fff;
    transition: 200ms linear;
  }
`;
