import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import { v4 as uuidv4 } from "uuid";
import {
  Content,
  TitleContainer,
  Button,
  FormDataField,
  DataFieldsWrapper,
  ButtonsListWrapper,
} from "./styles";

import { useToast } from "../../../../../hooks/toast";

import { FiX } from "react-icons/fi";
import { useState } from "react";
import ListView from "./ListView";

interface IContractField {
  [key: string]: string;
}
interface IFormListModal {
  open: boolean;
  functionToClose(): void;
  onSubmit(data: any): void;
  param: IContractField[];
  attributeName: string;
}

function defaultContractData(data: IContractField) {
  let obj = { ...data };
  Object.entries(obj).forEach(([key, value]) => (obj[key] = ""));
  return obj;
}

function hasData(data: IContractField) {
  let hasData = false;
  Object.entries(data).forEach(([key, value]) => {
    if (value !== "") hasData = true;
  });
  return hasData;
}

function setListDataWithId(list: IContractField[]) {
  let updatedList = [] as IContractField[];
  list.forEach((member) => updatedList.push({ ...member, id: uuidv4() }));
  return updatedList;
}

function removeIdFromList(list: IContractField[]) {
  let updatedList = [] as IContractField[];
  list.forEach((member) => {
    let updatedMember = {} as IContractField;
    Object.entries(member).forEach(([key, value]) => {
      if (key.toLowerCase() !== "id") updatedMember[key] = value;
    });
    updatedList.push(updatedMember);
  });
  return updatedList;
}
function FormListModal({
  open,
  functionToClose,
  onSubmit,
  param = [],
  attributeName = "",
}: IFormListModal) {
  const { addToast } = useToast();

  const [contractData, setContractData] = useState(
    defaultContractData(param[0])
  );

  const [listData, setListData] = useState<IContractField[]>(
    hasData(param[0]) ? setListDataWithId(param) : []
  );

  function handleChange(identifier: string, value: string) {
    setContractData((prevData) => ({ ...prevData, [identifier]: value }));
  }

  function handleAddListData() {
    const isInvalid = Object.entries(contractData).find(
      ([key, value]) => value.trim() === ""
    );

    if (isInvalid) {
      addToast({
        title: "Por favor preencha todos os campos!",
        type: "error",
      });
      return;
    }

    const formattedContractData = { ...contractData, id: uuidv4() };

    setListData((prevList) => [...prevList, formattedContractData]);
    setContractData(defaultContractData(contractData));
  }

  function handleDeleteUser(id: string) {
    setListData((prevList) => prevList.filter((value) => value.id !== id));
  }

  function handleSubmitList() {
    if (listData.length === 0) onSubmit([defaultContractData(param[0])]);
    else onSubmit(removeIdFromList(listData));
    functionToClose();
  }

  return (
    <Modal
      open={open}
      onClose={functionToClose}
      classNames={{
        modal: "FormListModal",
      }}
      closeIcon={<FiX size={18} color="#000" />}
    >
      <Content>
        <TitleContainer>
          <h3>Preencha os dados abaixo:</h3>
        </TitleContainer>
        <DataFieldsWrapper>
          {Object.entries(contractData).map(([key, value]) => {
            return (
              key.toLowerCase() !== "id" && (
                <FormDataField key={key}>
                  <label className="att-lbl">{key.toLowerCase()}</label>
                  <input
                    onChange={(event) => handleChange(key, event.target.value)}
                    value={value}
                  />
                </FormDataField>
              )
            );
          })}
        </DataFieldsWrapper>

        <ButtonsListWrapper>
          <Button color="#6ea4ff" onClick={handleAddListData}>
            Adicionar {attributeName.split(" ")[0].toLowerCase()}
          </Button>
          <ListView list={listData} onDeleteUser={handleDeleteUser} />
          <Button color="#00BF33" onClick={handleSubmitList}>
            Enviar dados
          </Button>
        </ButtonsListWrapper>
      </Content>
    </Modal>
  );
}

export default FormListModal;
