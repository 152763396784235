import styled from "styled-components";

export const Wrapper = styled.div`
  margin: auto;

  label {
    font-size: ${12 / 16}rem;
    text-transform: capitalize;
  }

  svg {
    cursor: pointer;
  }
`;
