import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import { Content, TitleContainer, ButtonsContainer } from "./styles";

import { FiX } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { MODEL_CONTRACT_TYPES } from "./constants";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import { useAuth } from "../../../../hooks/auth";
import { useToast } from "../../../../hooks/toast";
import refreshToken from "../../../../utils/refreshToken";
import ModelCreateContractSkeleton from "../ModalCreateContract/Skeleton/ModelCreateContractSkeleton";

interface IClient {
  drive_link: string;
  property_id: number;
  rent_process_id: number;
  tenant_email: string;
  tenant_id: number;
  tenant_name: string;
  tenant_phone: string;
}

interface IModalSelectModelType {
  open: boolean;
  functionToClose(): void;
  dataToShow: IClient;
}

function ModalSelectModelType({
  open,
  functionToClose,
  dataToShow,
}: IModalSelectModelType) {
  const { access_token, dateToExpires, user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const [contratModels, setContractModels] = useState({});
  const [fetchingContractModels, setFetchingContractModels] = useState(false);

  useEffect(() => {
    async function loadApi() {
      setFetchingContractModels(true);
      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${token ?? access_token}`;
        const response = await api.get(`/api/adm/contract-models`);

        setContractModels(response.data.data.models);
      } catch (err: any) {
        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }
      }
      setFetchingContractModels(false);
    }

    if (!!user) {
      loadApi();
    } else {
      history.push("/");
    }
  }, [access_token, addToast, dateToExpires, history, user]);

  function handleOpenContractPage(type: string) {
    history.push(`/contract-model/${type}`, { client: dataToShow });
  }

  return (
    <Modal
      open={open}
      onClose={functionToClose}
      classNames={{
        modal: "modalSelectModelType",
      }}
      closeIcon={<FiX size={18} color="#000" />}
    >
      <Content>
        <TitleContainer>
          <h3>Escolha o Modelo do Contrato:</h3>
        </TitleContainer>

        {fetchingContractModels && <ModelCreateContractSkeleton />}
        {!fetchingContractModels &&
          Object.entries(contratModels).length > 0 && (
            <ButtonsContainer>
              {Object.entries(contratModels).map(([key, value]) => {
                return (
                  <button
                    key={key}
                    onClick={() => handleOpenContractPage(value as string)}
                  >
                    {key}
                  </button>
                );
              })}
            </ButtonsContainer>
          )}
      </Content>
    </Modal>
  );
}

export default ModalSelectModelType;
