import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useAuth } from "../../../../hooks/auth";
import { useToast } from "../../../../hooks/toast";
import refreshToken from "../../../../utils/refreshToken";
import api from "../../../../services/api";
import { Content, Form, FormButton, FormDataField } from "./styles";
import ContentType from "./ContentType";
import FormListModal from "./FormListModal";
import ModelContractFormSkeleton from "./Skeleton/Skeleton";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { FaFileAlt } from "react-icons/fa";

interface IParams {
  type: string;
}

interface IContractField {
  [key: string]: string;
}

interface ContractData {
  [key: string]: IContractField | IContractField[];
}

interface ContractObj {
  [key: string]: string | boolean | ContractObj[];
}

interface IFormListModal {
  open: boolean;
  param: any;
  attributeName: string;
}

interface IProps {
  model: string;
  onSubmit: (data: ContractObj) => void;
}

function contentType(title: string) {
  const splittedTitle = title.split(" ");
  if (splittedTitle.find((word) => word.toLowerCase() === ContentType.Array))
    return ContentType.Array;
  if (splittedTitle.find((word) => word.toLowerCase() === ContentType.Boolean))
    return ContentType.Boolean;
  return ContentType.String;
}

function createObjFromData(data: ContractData | ContractObj) {
  const obj: ContractObj = {};

  Object.entries(data).forEach(([key, value]) => {
    if (contentType(key) === ContentType.String) obj[key] = "";
    else if (contentType(key) === ContentType.Boolean) obj[key] = false;
    else {
      const listData = {} as IContractField;
      const arrayValue = value as IContractField[];
      arrayValue.forEach((element) => (listData[element.label] = ""));
      obj[key] = [listData];
    }
  });
  return obj;
}

function FormStep({ onSubmit, model }: IProps) {
  const { access_token, dateToExpires, user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const [fetchingContractFields, setFetchingContractFields] = useState(false);
  const [contractData, setContractData] = useState<ContractObj>({});
  const [loadModal, setLoadModal] = useState<IFormListModal>({
    open: false,
    param: undefined,
    attributeName: "",
  });

  useEffect(() => {
    async function loadApi() {
      setFetchingContractFields(true);
      try {
        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${token ?? access_token}`;
        const response = await api.get(`/api/adm/contract-variables/${model}`);

        setContractData(createObjFromData(response.data.data));
      } catch (err: any) {
        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }
      }
      setFetchingContractFields(false);
    }

    if (!!user) {
      loadApi();
    } else {
      history.push("/");
    }
  }, [dateToExpires, access_token, user, history, addToast, model]);

  function handleChange(identifier: string, value: string | boolean) {
    setContractData((prevData) => ({ ...prevData, [identifier]: value }));
  }

  function handleOpenModal(open: boolean, param: any, attributeName: string) {
    setLoadModal((prevValue) => ({
      ...prevValue,
      open,
      param,
      attributeName,
    }));
  }

  function isFormValid(data: ContractObj) {
    let isValid = true;
    if (Object.entries(data).length === 0) {
      isValid = false;
    } else {
      Object.entries(data).forEach(([key, value]) => {
        if (contentType(key) === ContentType.String) {
          if (value.toString().trim() === "") isValid = false;
        }
        if (contentType(key) === ContentType.Array) {
          const arr = value as ContractObj[];
          arr.forEach((obj) => {
            Object.entries(obj).forEach(([key, value]) => {
              if (value.toString().trim() === "") isValid = false;
            });
          });
        }
      });
    }
    return isValid;
  }

  function handleSubmit() {
    if (!isFormValid(contractData)) {
      addToast({
        title: "Por favor preencha todos os campos!",
        type: "error",
      });
      return;
    }
    onSubmit(contractData);
    setContractData((prevValue) => ({
      ...prevValue,
      ...createObjFromData(prevValue),
    }));
  }

  return (
    <Content>
      {loadModal.open && (
        <FormListModal
          {...loadModal}
          functionToClose={() => handleOpenModal(false, undefined, "")}
          onSubmit={(data) => handleChange(loadModal.attributeName, data)}
        />
      )}
      {fetchingContractFields && <ModelContractFormSkeleton />}
      {!fetchingContractFields && (
        <Form>
          {Object.entries(contractData).map(([key, value]) => {
            return (
              <FormDataField key={key}>
                {contentType(key) === ContentType.String && (
                  <>
                    <label className="att-lbl">{key.toLowerCase()}</label>
                    <input
                      key={key}
                      onChange={(event) =>
                        handleChange(key, event.target.value)
                      }
                      value={contractData[key] as string}
                    />
                  </>
                )}
                {contentType(key) === ContentType.Array && (
                  <>
                    <label className="att-lbl">{key.toLowerCase()}</label>
                    <button
                      onClick={() => handleOpenModal(true, value, key)}
                      type="button"
                    >
                      Preencher
                    </button>
                  </>
                )}
                {contentType(key) === ContentType.Boolean && (
                  <>
                    <label className="att-lbl">{key.toLowerCase()}</label>
                    <FormControl style={{ margin: "auto 16px" }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        row
                        onChange={(event) =>
                          handleChange(
                            key,
                            event.target.value === "true" ? true : false
                          )
                        }
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio checked={value as boolean} />}
                          label="Sim"
                        />
                        <FormControlLabel
                          style={{
                            marginLeft: "4px",
                          }}
                          value={false}
                          control={<Radio checked={!value as boolean} />}
                          label="Não"
                        />
                      </RadioGroup>
                    </FormControl>
                  </>
                )}
              </FormDataField>
            );
          })}
        </Form>
      )}
      <FormButton onClick={handleSubmit}>
        <span id="styled-btn">
          Adicionar Dados <FaFileAlt size={24} />
        </span>
      </FormButton>
    </Content>
  );
}

export default FormStep;
