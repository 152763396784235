import { BiTrashAlt } from "react-icons/bi";
import { Wrapper } from "./styles";

interface IContractField {
  [key: string]: string;
}

interface IProps {
  list: IContractField[];
  onDeleteUser: (id: string) => void;
}

function containsName(title: string) {
  const splittedTitle = title.split(" ");

  return splittedTitle.find((name) => name.toLowerCase() === "nome");
}

function nameOrCharge(data: IContractField) {
  const listEntry = Object.entries(data).find(([key, value]) =>
    containsName(key)
  );

  const id = Object.entries(data).find(([key, value]) => key === "id");
  const [idKey, idValue] = id as [string, string];

  if (listEntry) {
    const [key, value] = listEntry;
    return { key, name: value, idValue };
  }

  const key = Object.keys(data)[0];
  return { key, name: data[key], idValue };
}

function ListView({ list = [], onDeleteUser }: IProps) {
  return (
    <Wrapper>
      {list.map((element) => {
        const { key, name, idValue } = nameOrCharge(element);
        return (
          <div key={idValue}>
            <label>
              {key.toLowerCase()}: {name}{" "}
              <BiTrashAlt
                color="hsl(360deg 100% 45%)"
                onClick={() => onDeleteUser(idValue)}
              />
            </label>
          </div>
        );
      })}
    </Wrapper>
  );
}

export default ListView;
