import { useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import ModelContractSteps from "./ModelContractSteps";
import UploadBeltImage from "../../components/UploadBeltImage";
import { useToast } from "../../hooks/toast";
import {
  MdCheck,
  MdChevronLeft,
  MdChevronRight,
  MdOutlineFileCopy,
  MdOutlineFileUpload,
} from "react-icons/md";
import { ButtonsContainer, Container, Content } from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SignersStep from "./components/SignersStep";
import FormStep from "./components/FormStep";
import { CONTRACT_STEPS } from "./constants";
import ContentType from "./components/FormStep/ContentType";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";

interface ISigner {
  signAs: string;
  email: string;
  name: string;
  signOutside: boolean;
  user_id: undefined | number;
}

interface IClient {
  drive_link: string;
  property_id: number;
  rent_process_id: number;
  tenant_email: string;
  tenant_id: number;
  tenant_name: string;
  tenant_phone: string;
}

interface IParam {
  client: IClient;
}

interface ContractObj {
  [key: string]: string | boolean | ContractObj[];
}

interface IParams {
  type: string;
}

function contentType(title: string) {
  const splittedTitle = title.split(" ");
  if (splittedTitle.find((word) => word.toLowerCase() === ContentType.Array))
    return ContentType.Array;
  if (splittedTitle.find((word) => word.toLowerCase() === ContentType.Boolean))
    return ContentType.Boolean;
  return ContentType.String;
}

const ModelContract: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const location = useLocation<IParam>();
  const { client } = location.state;
  const { params } = useRouteMatch<IParams>();
  const { access_token, dateToExpires, user } = useAuth();

  const [activeStep, setActiveStep] = useState(ModelContractSteps.FILES_UPLOAD);
  const [signers, setSigners] = useState<ISigner[]>([
    {
      signAs: "lessee",
      email: client.tenant_email,
      name: client.tenant_name,
      signOutside: false,
      user_id: client.tenant_id,
    },
  ]);
  const [isGeneratingContract, setIsGeneratingContract] = useState(false);
  const [contractWasGenerated, setContractWasGenerated] = useState(false);
  const [contractData, setContractData] = useState<ContractObj>({});
  const ICON_SIZE = 24;

  function isFormValid(data: ContractObj) {
    let isValid = true;
    if (Object.entries(data).length === 0) {
      isValid = false;
    } else {
      Object.entries(data).forEach(([key, value]) => {
        if (contentType(key) === ContentType.String) {
          if (value.toString().trim() === "") isValid = false;
        }
        if (contentType(key) === ContentType.Array) {
          const arr = value as ContractObj[];
          arr.forEach((obj) => {
            Object.entries(obj).forEach(([key, value]) => {
              if (value.toString().trim() === "") isValid = false;
            });
          });
        }
      });
    }
    return isValid;
  }

  const handleAddSigner = (registeredSigner: ISigner) => {
    setSigners((prevSigners) => [...prevSigners, registeredSigner]);
  };

  const onSignerRemoved = (signerEmail: string) => {
    setSigners((prevSigners) => {
      return prevSigners.filter((signer) => {
        return signer.email !== signerEmail;
      });
    });
  };

  function handleSubmitContractForm(data: ContractObj) {
    setContractData(data);
  }

  const forwardButtonClickHandler = () => {
    if (activeStep === ModelContractSteps.SIGNERS_REGISTER) {
      return;
    }
    if (!isFormValid(contractData)) {
      addToast({
        title: "Por favor adicione o contrato!",
        type: "error",
      });
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const backButtonClickHandler = () => {
    if (activeStep === ModelContractSteps.FILES_UPLOAD) {
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const exitButtonClickHandler = () => {
    history.push("/clients-without-contract");
  };

  function getContent() {
    switch (activeStep) {
      case ModelContractSteps.FILES_UPLOAD:
        return (
          <>
            <FormStep model={params.type} onSubmit={handleSubmitContractForm} />
            {Object.entries(contractData).length > 0 && (
              <span style={{ display: "flex", alignItems: "center" }}>
                Contrato adicionado <MdCheck size={ICON_SIZE} color="00BF33" />
              </span>
            )}
          </>
        );
      case ModelContractSteps.SIGNERS_REGISTER:
        return (
          <SignersStep
            signersList={signers}
            onRemoveSigner={onSignerRemoved}
            onFormSubmit={handleAddSigner}
          />
        );
    }
  }

  const contractGeneratedHandler = (isInGenerationProcess: boolean) => {
    addToast({
      title: isInGenerationProcess
        ? "O contrato está sendo gerado"
        : "O contrato já foi gerado",
      type: "error",
    });
  };

  const sendContractToApi = async (body: any) => {
    setIsGeneratingContract(true);
    try {
      api.defaults.headers.authorization = `Bearer ${access_token}`;
      const response = await api.post(
        `/api/adm/rent-contract/${client.property_id}`,
        body
      );
      console.log(response);
      addToast({ title: "Contrato criado com sucesso!", type: "success" });
      setContractWasGenerated(true);
    } catch (err: any) {
      if (err?.response?.status === 403) {
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }
    }
    setIsGeneratingContract(false);
  };

  const generateContractHandler = () => {
    let signersIsValid = true;
    let rentProcessIdIsValid = true;

    if (signers.length === 0) {
      signersIsValid = false;
      addToast({ title: "Necessário pelo menos um signatário", type: "error" });
    }

    if (!client.rent_process_id) {
      rentProcessIdIsValid = false;
      addToast({
        title: "Necessário identificador do processo de locação",
        type: "error",
      });
    }

    if (!signersIsValid || !rentProcessIdIsValid) return;

    const body = {
      rent_process_id: client.rent_process_id,
      signers,
      model: params.type,
      contract_data: contractData,
    };

    sendContractToApi(body);
  };

  return (
    <>
      <Header />

      <Container>
        <Content>
          <h2>Criação de contrato</h2>
          <p>
            Preencha todos os campos com atenção e confira todas as informações
            antes de finalizar.
          </p>
          <UploadBeltImage currentStep={activeStep} steps={CONTRACT_STEPS} />
          {getContent()}
          <ButtonsContainer>
            <button
              id={"back-btn"}
              onClick={
                activeStep === ModelContractSteps.FILES_UPLOAD
                  ? exitButtonClickHandler
                  : backButtonClickHandler
              }
            >
              <span>
                <MdChevronLeft size={ICON_SIZE} fontWeight={700} /> Voltar
              </span>
            </button>
            <button
              id={
                activeStep === ModelContractSteps.SIGNERS_REGISTER
                  ? contractWasGenerated || isGeneratingContract
                    ? "disable-btn"
                    : "last-btn"
                  : "forward-btn"
              }
              onClick={
                activeStep === ModelContractSteps.SIGNERS_REGISTER
                  ? contractWasGenerated || isGeneratingContract
                    ? () => contractGeneratedHandler(isGeneratingContract)
                    : generateContractHandler
                  : forwardButtonClickHandler
              }
            >
              {activeStep === ModelContractSteps.SIGNERS_REGISTER ? (
                isGeneratingContract ? (
                  "Gerando contrato..."
                ) : (
                  <span className="styled-btn">
                    Gerar Contrato <MdOutlineFileUpload size={ICON_SIZE} />
                  </span>
                )
              ) : (
                <span>
                  Próximo <MdChevronRight size={ICON_SIZE} fontWeight={700} />
                </span>
              )}
            </button>
            {activeStep === ModelContractSteps.SIGNERS_REGISTER && (
              <button onClick={exitButtonClickHandler}>
                <span className="styled-btn">
                  Todos os Contratos <MdOutlineFileCopy size={ICON_SIZE} />
                </span>
              </button>
            )}
          </ButtonsContainer>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default ModelContract;
