import { Radio } from "@material-ui/core";
import {
  Form,
  ListAddWrapper,
  OwnerList,
  PermissionRadioWrapper,
  PermissionsLabelRadiosWrapper,
  PropertyAccommodationsWrapper,
  PropertyConsultantWrapper,
  PropertyOwnerConsultantWrapper,
  PropertyOwnerWrapper,
  PropertyPermissionsWrapper,
  PropertyTypesContainer,
  PropertyValuesWrapper,
} from "./styles";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  ACCOMMODATION_OPTIONS,
  FLOOR_TYPES,
  PROPERTY_PERMISSIONS,
  PROPERTY_TYPES,
  PROPERTY_VALUES,
  RANK_OPTIONS,
} from "./constants";
import DescriptionRadio from "./DescriptionRadio";
import { PropertyRankContainer } from "../../../EditProperty/styles";
import Select from "react-select";
import { ButtonsWrapper, NextButton, ResetButton } from "../../styles";
import { FiArrowRight } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";
import getValidationErrors, {
  Errors,
} from "../../../../utils/getValidationError";
import axios, { CancelTokenSource } from "axios";
import api from "../../../../services/api";
import { useAuth } from "../../../../hooks/auth";
import { useToast } from "../../../../hooks/toast";
import refreshToken from "../../../../utils/refreshToken";
import { useConsultant } from "../../../../hooks/consultant";
import { useHistory } from "react-router-dom";
import PropertyAccommodation from "./PropertyAccommodation";
import PropertyValue from "./PropertyValue";
import GuaranteesSection from "./GuaranteesSection";
import * as Yup from "yup";
import FloorTypeOptions from "./enums/FloorTypeOptions";
import { MdClose, MdPersonAdd, MdPersonSearch } from "react-icons/md";
import ModalAddOwner from "./ModalAddOwner";

interface IOwner {
  id: number;
  first_name: string;
  email: string;
  phone: string;
}

interface IUserData {
  first_name: string;
  email: string;
  phone: string;
}

interface IProperty {
  property_type_id: number;
  floor_type: string;
  user_id?: number;
  user_data?: IUserData;
  consultant_id: number | undefined;
  address_id: number | undefined;
  title: string;
  description: string;
  rooms: number | undefined;
  suites: number | undefined;
  bathrooms: number | undefined;
  garages: number | undefined;
  year_built: string;
  rent_value: number;
  condominium_value: number;
  iptu_value: number;
  garbage_tax: number;
  area: string;
  is_furnished: number;
  allow_pets: number;
  rank: number | undefined;
  water_included: number;
  gas_included: number;
  guarantees: number[];
}

interface IConsultant {
  id: number;
  first_name: string;
}

interface IField {
  [key: string]: any;
}

interface IProps {
  address_id: number | undefined;
  onChangeCurrentForm: () => void;
  onPropertyIdReceived: (id: number) => void;
}

function deriveConsultant(
  consultantId: number | undefined,
  consultantsList: IConsultant[]
) {
  const selectedConsultant = consultantsList.find(
    (consultant) => consultant.id === consultantId
  );
  return selectedConsultant
    ? { label: selectedConsultant.first_name, value: selectedConsultant.id }
    : undefined;
}

const defaultProperty = {
  property_type_id: 1,
  floor_type: FloorTypeOptions.LOW,
  consultant_id: undefined,
  address_id: undefined,
  title: "",
  description: "",
  rooms: undefined,
  suites: undefined,
  bathrooms: undefined,
  garages: undefined,
  year_built: "",
  rent_value: 0,
  condominium_value: 0,
  iptu_value: 0,
  garbage_tax: 0,
  area: "0",
  is_furnished: 0,
  allow_pets: 0,
  rank: undefined,
  water_included: 0,
  gas_included: 0,
  guarantees: [1],
};

interface IModal {
  open: () => void;
  close: () => void;
}

function DescriptionForm({
  address_id,
  onChangeCurrentForm,
  onPropertyIdReceived,
}: IProps) {
  const { access_token, dateToExpires, user } = useAuth();
  const { addToast } = useToast();
  const { consultantsSaved, handleAddConsultants } = useConsultant();
  const history = useHistory();

  const [property, setProperty] = useState(defaultProperty as IField);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isFetchingConsultants, setIsFetchingConsultants] = useState(true);
  const [isFetchingOwners, setIsFetchingOwners] = useState(false);
  const [owners, setOwners] = useState<IOwner[]>([]);
  const [consultants, setConsultants] = useState<IConsultant[]>([]);
  const [ownerSearchTerm, setOwnerSearchTerm] = useState("");
  const [formErrors, setFormErrors] = useState({} as Errors);

  const dialog = useRef<IModal>(null);

  let cancelToken: undefined | CancelTokenSource;

  const consultantsOptions = consultants.map((consultant) => ({
    label: consultant.first_name,
    value: consultant.id,
  }));

  useEffect(() => {
    async function loadApi() {
      try {
        setIsFetchingConsultants(true);

        const token = await refreshToken(dateToExpires, access_token);

        api.defaults.headers.authorization = `Bearer ${token ?? access_token}`;

        if (consultantsSaved.length < 1) {
          const response = await api.get("/api/adm/users", {
            params: {
              role: "consultant",
            },
          });

          handleAddConsultants(response.data.data.consultants);

          setConsultants(response.data.data.consultants);
        } else {
          setConsultants(consultantsSaved);

          return;
        }
      } catch (err) {
        console.log(err);

        if (!!err.response?.data.message) {
          addToast({
            title: err.response.data.message,
            type: "error",
          });
        }
      }
      setIsFetchingConsultants(false);
    }

    if (!!user) {
      loadApi();
    } else {
      history.push("/");
    }

    // eslint-disable-next-line
  }, [
    consultantsSaved,
    dateToExpires,
    access_token,
    user,
    history,
    handleAddConsultants,
  ]);

  function handleChange(identifier: string, value: any) {
    setProperty((prevValue) => ({ ...prevValue, [identifier]: value }));
  }

  async function handleSearchChange(e: ChangeEvent<HTMLInputElement>) {
    const searchTerm = e.target.value;

    setOwnerSearchTerm(searchTerm);
    setIsFetchingOwners(true);
    if (cancelToken !== undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    // eslint-disable-next-line
    cancelToken = axios.CancelToken.source();

    try {
      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${token ?? access_token}`;

      const response = await api.get("/api/adm/users", {
        params: {
          email: searchTerm,
        },
        cancelToken: cancelToken!.token,
      });

      if (response.data.data.users.length === 0) {
        addToast({
          title: "Nenhum usuário encontrado",
          type: "error",
        });
      }

      setOwners(response.data.data.users);
    } catch (err) {
      console.log(err);
    }
    setIsFetchingOwners(false);
  }

  function handleSelectOwner(user_id: number | undefined) {
    const user_selected = owners.find((owner) => owner.id === user_id);
    setOwners([]);

    if (!!user_selected) {
      handleChange("user_id", user_selected.id);
      // setOwnerEmail(user_selected?.email);
      setOwnerSearchTerm(user_selected?.email);
    }
  }

  function handleGuaranteeChange(event: any) {
    if (event.target.checked) {
      setProperty((prevValue) => ({
        ...prevValue,
        guarantees: [...prevValue.guarantees, Number(event.target.value)],
      }));
    } else
      setProperty((prevValue) => ({
        ...prevValue,
        guarantees: prevValue.guarantees.filter((guarantee: number) => {
          return Number(event.target.value) !== guarantee;
        }),
      }));
  }

  function formatCurrency(value: number) {
    const stringValue = value.toString();
    return Number(
      `${stringValue.substring(
        0,
        stringValue.length - 2
      )}.${stringValue.substring(stringValue.length - 2, stringValue.length)}`
    );
  }

  function handleAddOwner() {
    setOwnerSearchTerm("");
    handleChange("user_id", undefined);
    dialog.current?.open();
  }

  function handleCloseModal() {
    dialog.current?.close();
  }
  async function handleSubmit() {
    setIsSubmiting(true);
    const formattedProperty = {
      ...property,
      address_id,
      area: Number(property.area.replaceAll("m²", "").replaceAll(",", ".")),
      garbage_tax: formatCurrency(property.garbage_tax),
      iptu_value: formatCurrency(property.iptu_value),
      rent_value: formatCurrency(property.rent_value),
      condominium_value: formatCurrency(property.condominium_value),
    };
    try {
      const schema = Yup.object().shape({
        property_type_id: Yup.number().required("Informe o tipo do imóvel"),
        consultant_id: Yup.number().required("Consultante obrigatório"),
        title: Yup.string().required("Título obrigatório"),
        description: Yup.string().required("Descrição obrigatória"),
        rooms: Yup.number()
          .required("Número de quartos obrigatório")
          .min(1, "Deve conter no mínimo 1 quarto"),
        suites: Yup.number()
          .required("Número de suítes obrigatório")
          .max(5, "Número máximo de quartos atingido"),
        bathrooms: Yup.number()
          .required("Número de banheiros obrigatório")
          .max(10, "Número máximo de quartos atingido"),
        garages: Yup.number()
          .required("Número de vagas de garagem obrigatório")
          .max(5, "Número máximo de quartos atingido"),
        rent_value: Yup.string().required("Valor do aluguel obrigatório"),
        condominium_value: Yup.string().required(
          "Valor do condomínio obrigatório"
        ),
        iptu_value: Yup.string().required("Valor do iptu obrigatório"),
        area: Yup.string().required("Área obrigatória"),
        allow_pets: Yup.number().required("Informe se o imóvel permite pets"),
        guarantees: Yup.array()
          .required("Mínimo de 1 garantia")
          .min(1, "Necessária ao menos 1 garantia"),
      });

      await schema.validate(formattedProperty, {
        abortEarly: false,
      });

      const token = await refreshToken(dateToExpires, access_token);

      api.defaults.headers.authorization = `Bearer ${
        !!token ? token : access_token
      }`;

      const response = await api.post("/api/adm/properties", {
        ...formattedProperty,
      });

      if (!!response.data.success) {
        onPropertyIdReceived(response.data.data.data.id);

        setFormErrors({});
        onChangeCurrentForm();

        addToast({
          title: "Descrição cadastrada!",
          type: "success",
        });
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = getValidationErrors(err);

        addToast({
          title: error[Object.keys(error)[0]],
          type: "info",
          description: "",
        });

        setFormErrors(error);
      } else if (
        !!err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]
      ) {
        const errorsNumber = Object.keys(err.response.data.errors).length;

        for (let i = 0; i < errorsNumber; i++) {
          err.response.data.errors[
            Object.keys(err.response.data.errors)[i]
          ].forEach((error: string) => {
            addToast({
              title: error,
              type: "error",
            });
          });
        }
      }

      if (!!err.response?.data.message) {
        addToast({
          title: err.response.data.message,
          type: "error",
        });
      }

      console.log(err);
    }

    setIsSubmiting(false);
  }

  return (
    <>
      <ModalAddOwner
        onAddOwner={handleChange}
        onClose={handleCloseModal}
        owner={property.user_data}
        ref={dialog}
      />
      <Form>
        <p id="initial-label">
          Tipo do imóvel:{" "}
          {!!formErrors.property_type_id && (
            <p>{formErrors.property_type_id}</p>
          )}
        </p>
        <PropertyTypesContainer>
          {PROPERTY_TYPES.map((propertyType) => (
            <DescriptionRadio
              key={propertyType.value}
              {...propertyType}
              name="selector-group2"
              checked={property.property_type_id === propertyType.value}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange(
                  "property_type_id",
                  Number.parseInt(event.target.value)
                )
              }
              onClick={() =>
                handleChange("property_type_id", propertyType.value)
              }
            />
          ))}
        </PropertyTypesContainer>

        <PropertyRankContainer>
          <label>
            Ranking {!!formErrors.rooms && <p>{formErrors.rank}</p>}
          </label>

          <Select
            placeholder=""
            options={RANK_OPTIONS}
            onChange={(e) => handleChange("rank", e?.value)}
          />
        </PropertyRankContainer>

        <p id="floor-type">Tipo de andar</p>
        <PropertyTypesContainer>
          {FLOOR_TYPES.map((floorType) => (
            <DescriptionRadio
              key={floorType.value}
              {...floorType}
              checked={property.floor_type === floorType.value}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChange("floor_type", event.target.value)
              }
              onClick={() => handleChange("floor_type", floorType.value)}
            />
          ))}
        </PropertyTypesContainer>

        <PropertyOwnerConsultantWrapper>
          <PropertyOwnerWrapper>
            <span>
              * Digite ao menos 4 caracteres e pressione enter para pesquisar
            </span>
            <p>
              Proprietário cadastrado:{" "}
              {!!formErrors.owner_email && <p>{formErrors.owner_email}</p>}
            </p>

            <OwnerList>
              <ListAddWrapper>
                {!property.user_data && (
                  <>
                    <input
                      value={ownerSearchTerm}
                      onChange={
                        ownerSearchTerm.length <= 3
                          ? (e) => setOwnerSearchTerm(e.target.value)
                          : handleSearchChange
                      }
                    />
                    <MdPersonAdd onClick={handleAddOwner} />
                  </>
                )}
                {property.user_data && (
                  <>
                    <p>
                      {property.user_data.email}{" "}
                      <span>
                        <MdClose
                          onClick={() => handleChange("user_data", undefined)}
                        />
                      </span>
                    </p>
                    <MdPersonSearch onClick={handleAddOwner} />
                  </>
                )}
              </ListAddWrapper>
              {owners.length >= 1 ? (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setOwnerSearchTerm("");
                    setOwners([]);
                  }}
                >
                  {!isFetchingOwners && (
                    <div id="users">
                      {owners.map((owner) => (
                        <button
                          key={owner.id}
                          type="button"
                          onClick={() => handleSelectOwner(owner.id)}
                        >
                          {owner.email}
                        </button>
                      ))}
                    </div>
                  )}
                  {isFetchingOwners && <div id="users">Carregando...</div>}
                </OutsideClickHandler>
              ) : null}
            </OwnerList>
          </PropertyOwnerWrapper>

          <PropertyConsultantWrapper>
            <p>
              Consultor responsável:{" "}
              {!!formErrors.property_consultant && (
                <p>{formErrors.property_consultant}</p>
              )}
            </p>

            <Select
              value={deriveConsultant(property.consultant_id, consultants)}
              options={consultantsOptions}
              placeholder={"Selecione um Consultor"}
              noOptionsMessage={() =>
                isFetchingConsultants ? "Carregando" : ""
              }
              onChange={(e) => {
                handleChange("consultant_id", e?.value);
              }}
            />
          </PropertyConsultantWrapper>
        </PropertyOwnerConsultantWrapper>

        <p>
          Qual é o título da publicação:{" "}
          {!!formErrors.title && <p>{formErrors.title}</p>}
        </p>
        <input
          type="text"
          value={property.title}
          onChange={(e) => handleChange("title", e.target.value)}
        />

        <p>
          Descrição Completa{" "}
          {!!formErrors.description && <p>{formErrors.description}</p>}
        </p>
        <textarea
          value={property.description}
          onChange={(e) => handleChange("description", e.target.value)}
        />

        <p>Ano de construção do prédio:</p>
        <input
          type="number"
          value={property.year_built}
          onChange={(e) => handleChange("year_built", e.target.value)}
        />

        <PropertyAccommodationsWrapper>
          {ACCOMMODATION_OPTIONS.map((accomodation) => (
            <PropertyAccommodation
              key={accomodation.attributeName}
              {...accomodation}
              formError={formErrors[accomodation.attributeName]}
              onChange={(e) =>
                handleChange(accomodation.attributeName, e?.value)
              }
            />
          ))}
        </PropertyAccommodationsWrapper>

        <PropertyValuesWrapper>
          {PROPERTY_VALUES.map((propertyValue) => (
            <PropertyValue
              key={propertyValue.attributeName}
              {...propertyValue}
              value={
                property[
                  propertyValue.attributeName as keyof IProperty
                ] as number
              }
              formError={formErrors[propertyValue.attributeName]}
              onChange={(e) => handleChange(propertyValue.attributeName, e)}
            />
          ))}
        </PropertyValuesWrapper>
        <GuaranteesSection
          onChange={handleGuaranteeChange}
          selectedGuarantees={property.guarantees}
        />
        <PropertyPermissionsWrapper>
          {PROPERTY_PERMISSIONS.map((permission) => (
            <PermissionsLabelRadiosWrapper key={permission.attributeName}>
              <label>{permission.label}</label>

              <PermissionRadioWrapper>
                <Radio
                  name={permission.name}
                  checked={!!property[permission.attributeName]}
                  onChange={() => handleChange(permission.attributeName, 1)}
                />
                <p onClick={() => handleChange(permission.attributeName, 1)}>
                  Sim
                </p>
                <Radio
                  name={permission.name}
                  checked={!property[permission.attributeName]}
                  onChange={() => handleChange(permission.attributeName, 0)}
                />
                <p onClick={() => handleChange(permission.attributeName, 0)}>
                  Não
                </p>
              </PermissionRadioWrapper>
            </PermissionsLabelRadiosWrapper>
          ))}
        </PropertyPermissionsWrapper>

        <ButtonsWrapper>
          <ResetButton
            type="button"
            onClick={() => {
              setProperty((prevValue) => ({
                ...prevValue,
                ...defaultProperty,
              }));
            }}
          >
            LIMPAR
          </ResetButton>

          <NextButton
            type="button"
            onClick={!!isSubmiting ? () => {} : handleSubmit}
          >
            <p>{!!isSubmiting ? "Carregando..." : "Próximo"}</p>
            {!isSubmiting && <FiArrowRight size={18} color="#FFF" />}
          </NextButton>
        </ButtonsWrapper>
      </Form>
    </>
  );
}

export default DescriptionForm;
