import styled from "styled-components";

export const Content = styled.div`
  max-width: 740px;

  @media(max-width: 767px){
  }
`;

export const TitleContainer = styled.div`
  border-bottom: 1px solid #bcbfc2;
  display: flex;
  justify-content: center;

  padding: 24px 0px 24px 0px;

  width: 400px;
  > h3 {
    color: #666666;

    font-weight: 600;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    padding: 24px 0 24px 24px;

    width: 330px;
  }
`;

export const VideoCallContainer = styled.div`
  width: 90%;

  margin: 12px auto;

  padding: 16px;

  background-color: #f5c6cb;

  border-radius: 4px;
`;

export const DataContainer = styled.div`
  width: 320px;

  padding-bottom: 4px;

  align-items: flex-start;
  justify-content: center;

  flex-direction: column;

  margin: 0px 40px 0px;

  > h3 {
    color: #dd6435;
    font-size: 16px;

    font-weight: 500;

    margin: 12px 0 4px;
  }

  & svg {
    cursor: pointer;

    &:hover {
      opacity: 70%;
    }
  }

  > p {
    color: #666666;
    font-size: 14px;
    > a {
      color: #666666;
    }
    > a:hover {
      color: #b9b9b9;
    }
    & + p {
      margin-top: 4px;
    }
  }

  @media(max-width: 767px){
    width: 80%;
  }
`;

export const ButtonContainer = styled.div`
  margin: 16px 20px 16px;

  display: flex;

  justify-content: center;

  > button {
    width: 43%;

    border: 0;

    padding: 12px;
    margin: 0 2%;

    color: #fff;

    font-weight: 500;
  }

  .btn-close {
    background-color: #bf0001;
  }

  .btn-edit {
    background-color: #6ea5ff;
  }

  @media (max-width: 767px) {
    width: 90%;
  }
`;
