import React from "react";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import {
  Content,
  TitleContainer,
  VideoCallContainer,
  DataContainer,
  ButtonContainer,
} from "./styles";

import { FiX } from "react-icons/fi";
import useModalFetch from "../../hooks/useModalFetch";
import ModalSkeleton from "../ModalSkeleton";
import ModalType from "../../pages/Schedules/ModalType";
import { MdEdit } from "react-icons/md";
import AddressLink from "./AddressLink";

interface IModalConsult {
  open: boolean;
  functionToClose: () => void;
  functionToDemarcate: () => void;
  functionToUpdateUser: (identifier: string, param: any) => void;
  functionToUpdate: () => void;
  param: string;
}

interface IProperty {
  address: string;
  city: string;
  id: number;
  neighborhood: string;
  number: string;
  photo: string;
  title: string;
  complement: null | string;
  uf: string;
}

const formatDate = (schedule: string) => {
  const date = new Date(schedule);

  return `${String(date.getDate()).padStart(2, "0")}/${String(
    date.getMonth() + 1
  ).padStart(2, "0")}/${date.getFullYear()} - ${date.getHours()}:00`;
};

const formatAddress = (property: IProperty) => {
  return `${property.address} ${property.number ?? ""} ${
    property.neighborhood
  } ${property.complement ? "," + property.complement : ""} | ${
    property.city
  } - ${property.uf}`;
};

const ModalConsult: React.FC<IModalConsult> = ({
  open,
  functionToClose,
  functionToDemarcate,
  functionToUpdateUser,
  functionToUpdate,
  param,
}) => {
  const { fetchedData, isFetching, error } = useModalFetch(
    `/api/adm/schedules/${param}`
  );

  const property = fetchedData.property;
  const consultant = fetchedData.consultant;

  let content = <ModalSkeleton />;
  if (!isFetching && Object.keys(fetchedData).length !== 0)
    content = (
      <Content>
        <TitleContainer>
          <h3>DETALHES DA VISITA</h3>
        </TitleContainer>
        {!!fetchedData.video_call && (
          <VideoCallContainer>
            <p>Visita via video chamada</p>
          </VideoCallContainer>
        )}
        <DataContainer>
          <img
            src={property.photo ?? undefined}
            alt="Foto do imóvel"
            width="100%"
            height="180px"
            style={{ margin: "0.5rem 0" }}
          />
          <h3>
            CLIENTE{" "}
            <MdEdit
              onClick={() =>
                functionToUpdateUser(ModalType.UPDATE_USER, {
                  ...fetchedData.client,
                  schedule: param,
                })
              }
            />
          </h3>

          <p>
            <b>Nome: </b> {fetchedData.client.first_name}
          </p>
          <p>
            <b>Email: </b> {fetchedData.client.email}
          </p>
          <p>
            <b>Telefone: </b> {fetchedData.client.phone}
          </p>
        </DataContainer>
        <DataContainer>
          <h3>
            PROPRIETÁRIO{" "}
            <MdEdit
              onClick={() =>
                functionToUpdateUser(ModalType.UPDATE_USER, {
                  ...fetchedData.owner,
                  schedule: param,
                })
              }
            />
          </h3>
          {fetchedData.owner && (
            <>
              <p>
                <b>Nome: </b> {fetchedData.owner.first_name}
              </p>
              <p>
                <b>Email: </b> {fetchedData.owner.email}
              </p>
              <p>
                <b>Telefone: </b> {fetchedData.owner.phone}
              </p>
            </>
          )}
          {!fetchedData.owner && <p>Não há proprietário cadastrado.</p>}
        </DataContainer>

        <DataContainer>
          <h3>AGENDAMENTO</h3>
          <p>
            <b>Data e horário: </b> {formatDate(fetchedData.schedule.date)}
          </p>
          <p>
            <b>Código do imóvel: </b>{" "}
            <a
              href={`https://www.7cantos.com/imovel/${property.id}`}
              rel="noreferrer"
              target="_blank"
            >
              {property.id}
            </a>
          </p>
          <p>
            <b>Endereço: </b>{" "}
            <AddressLink
              address={formatAddress(fetchedData.property)}
              service="waze"
            />
          </p>
          <p>
            <b>Consultor: </b> {consultant.first_name} - {consultant.phone}
          </p>
        </DataContainer>

        <ButtonContainer>
          <button className="btn-close" onClick={functionToDemarcate}>
            DESMARCAR
          </button>
          <button className="btn-edit" onClick={functionToUpdate}>
            ALTERAR
          </button>
        </ButtonContainer>
      </Content>
    );

  return (
    <Modal
      open={open}
      onClose={functionToClose}
      classNames={{
        modal: "modalConsult",
      }}
      closeIcon={<FiX size={18} color="#000" />}
    >
      {content}
    </Modal>
  );
};

export default ModalConsult;
