import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;
`;

export const Form = styled.form`
  width: 90%;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-between;
  margin-top: 2.5rem;

  > label {
    font-weight: 600;
    font-size: 1rem;
    color: #606060;
    margin-top: 0.5rem;
  }

  > label#name-lbl {
    margin-top: 2rem;
  }

  label.att-lbl {
    text-transform: capitalize;
  }

  input {
    width: 100%;
  }

  > input {
    margin-top: 0.75rem;
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
    border-radius: 0.8em;
    border: 1px solid #b6b6b6;
  }

  @media (max-width: 767px) {
    grid-template-columns: 48% 48%;
  }
`;
export const FormDataField = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-direction: column;

  > label {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    font-size: 14px;
    font-weight: 500;

    color: #666666;

    > p {
      margin-left: 6px;

      font-size: 10px;
      font-weight: bold;
    }
  }

  > input {
    width: 100%;

    border: 0;
    border-bottom: 1px solid #b6b6b6;

    color: #666666;

    background: transparent;

    padding: 4px 8px;

    margin-bottom: 8px;
  }

  > button {
    padding: 4px;
    margin: 8px 4px;
    color: #dd6435;
    background-color: #fff;
    border: 1px solid #dd6435;
    border-radius: 4px;
    font-size: ${12 / 16}rem;

    &:hover {
      background-color: #dd6435;
      color: #fff;
      transition: 200ms linear;
    }
  }

  @media (max-width: 767px) {
    & + div {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;

export const FormButton = styled.button`
  background-color: #6da7ff;
  font-weight: 700;
  color: #fff;
  min-width: 33%;
  border-radius: 2rem;
  font-size: 1rem;
  padding: 0.75em 0;
  margin: 3rem auto 1rem;
  > span#styled-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3%;
  }
`;
